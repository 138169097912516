import Backbone from 'backbone';
import $ from 'jquery';
import ControllerShowView from '@assets/javascripts/backbone/views/controllers/controller_show_view.js';
import '@vendor/javascripts/jquery-plugins/jquery.dropdown-form.js.coffee';

const ControllerEditingView = Backbone.View.extend({
  el: '#controls-show',
  events: {
    'click .header.open': 'open',
    'click .header.open div': 'opendiv',
    'click .header.open a': 'open2',
    'click .header.closed': 'close',
    'click .header.closed div': 'closediv',
    'click .header.closed a': 'close2',
    'click .dropdown-form-enable': 'enable',
    'click .dropdown-form-disable': 'disable',
    'click .show-zone-pic': 'view_photo',
  },
  initialize() {
    this.checkDropdownForm();
  },
  checkDropdownForm() {
    if (typeof $.fn.dropdownForm === 'function') {
      this.initializeDropdowns();
    } else {
      setTimeout(this.checkDropdownForm.bind(this), 100);
    }
  },
  initializeDropdowns() {
    $('#area-seasonal-adjust .dropdown-form').dropdownForm({ success: this.update_seasonal_adjust }, { trigger: '.dropdown-trigger.enabled' });
    $('#area-zone-run-times .dropdown-form').dropdownForm({ success: this.update_zone_run_time.bind(this), error: this.update_zone_run_time_err }, { trigger: '.dropdown-trigger.enabled' });
    $('#area-auto-adjust .dropdown-form').dropdownForm({ success: this.update_auto_adjust.bind(this), error: this.update_auto_adjust_err }, { trigger: '.dropdown-trigger.enabled' });
  },
  open(e) {
    e.preventDefault();
    $(e.target).removeClass('open').addClass('closed').parent()
      .find('.stuff')
      .addClass('hidden');
  },

  opendiv(e) {
    e.preventDefault();
    $(e.target).parent().removeClass('open').addClass('closed')
      .parent()
      .find('.stuff')
      .addClass('hidden');
  },

  open2(e) {
    e.preventDefault();
    $(e.target).parent().parent().removeClass('open')
      .addClass('closed')
      .parent()
      .find('.stuff')
      .addClass('hidden');
  },

  close(e) {
    e.preventDefault();
    $(e.target).removeClass('closed').addClass('open').parent()
      .find('.stuff')
      .removeClass('hidden');
  },

  closediv(e) {
    e.preventDefault();
    $(e.target).parent().removeClass('closed').addClass('open')
      .parent()
      .find('.stuff')
      .removeClass('hidden');
  },

  close2(e) {
    e.preventDefault();
    $(e.target).parent().parent().removeClass('closed')
      .addClass('open')
      .parent()
      .find('.stuff')
      .removeClass('hidden');
  },

  enable(e) {
    console.log(e);
    e.preventDefault();
    $('.dropdown-trigger').addClass('enabled');
    $('.location.hidden').removeClass('hidden');
    $('.dropdown-form-enable').addClass('hidden');
    $('.dropdown-form-disable').removeClass('hidden');
  },
  // $(e.target).closest("form").find(".hidden").removeClass("hidden")

  disable() {
    // it would be good to allow this to disable the form instead of reload the page, but we need to reload to get the 'send' button
    //      e.preventDefault()
    //      $('.dropdown-trigger').removeClass('enabled')
    //      $('.dropdown-form-enable').removeClass('hidden')
    //      $('.dropdown-form-disable').addClass('hidden')
  },

  update_seasonal_adjust(data, textStatus, jqXHR, dropdown) {
    dropdown.find('.dropdown-trigger').text(`${data.percentage}%`);
  },
  update_location_for_table(id, index, zoneDesc) {
    const mobileHeader = $(`${id} .phone-advanced-table .header-title`)[index];
    mobileHeader.textContent = `${index + 1}: ${zoneDesc}`;
    const mobileLocation = $(`${id} .phone-advanced-table .dropdown-trigger[data-form='.description-form']`)[index];
    mobileLocation.dataset.value = zoneDesc;
    mobileLocation.textContent = zoneDesc;
    const desktopLocation = $(`${id} .desktop-environment .dropdown-trigger[data-form='.description-form']`)[index];
    desktopLocation.dataset.value = zoneDesc;
    desktopLocation.textContent = zoneDesc;
  },
  update_zone_run_time(data, textStatus, jqXHR, dropdown) {
    // this response handles updating zone run times OR descriptions...
    if (data.meta.request.zone.program_zones) { // this conditional checks if the response is from updating a run time
      const runtime = data.meta.request.zone.program_zones[0].run_time;
      if (runtime === '0') { // json response gives a string, not an int
        dropdown.find('.dropdown-trigger').text('OFF');
      } else {
        dropdown.find('.dropdown-trigger').text(runtime);
      }
      ControllerShowView.prototype.showHideUsedBox({ currentTarget: dropdown.find('.dropdown-trigger') });
    } else {
      // otherwise it was from updating a description
      const zoneDesc = data.result.zone.description;
      dropdown.find('.dropdown-trigger')[0].dataset.value = zoneDesc;
      dropdown.find('.dropdown-trigger').text(zoneDesc);
      const index = $(dropdown).data('index');
      const mobileHeader = $('#area-zone-run-times .phone-advanced-table .header-title')[index];
      mobileHeader.textContent = `${index + 1}: ${zoneDesc}`;
      // Update Location for Smart Mode table if controller_type == 0
      if ($('#area-auto-adjust').length) {
        this.update_location_for_table('#area-auto-adjust', index, zoneDesc);
      }
    }
  },

  update_zone_run_time_err(data, textStatus, jqXHR) {
    console.log(jqXHR);
    alert('This was an error saving your data!');
  },

  update_auto_adjust(data, textStatus, jqXHR, dropdown) {
    const field = $(dropdown).data('field');
    if (typeof (data.result.zone[field]) !== 'undefined') {
      const fieldValue = data.result.zone[field];
      if (field === 'description') {
        const zoneDesc = data.result.zone.description;
        dropdown.find('.dropdown-trigger')[0].dataset.value = zoneDesc;
        dropdown.find('.dropdown-trigger').text(zoneDesc);
        const index = $(dropdown).data('index');
        const mobileHeader = $('#area-auto-adjust .phone-advanced-table .header-title')[index];
        mobileHeader.textContent = '#{index + 1}: #{zoneDesc}';
        //  Update Location for Zone Run Times table
        this.update_location_for_table('#area-zone-run-times', index, zoneDesc);
      } else {
        const form = $(dropdown).find('.dropdown-trigger').data('form');
        const text = $(form).find(`li[data-value=${fieldValue}]`).text();
        dropdown.find('.dropdown-trigger').text(text);
      }
    }
  },

  update_auto_adjust_err(data, textStatus, jqXHR) {
    console.log(jqXHR);
    return alert('There was an error saving your data!');
  },

  view_photo(e) {
    e.preventDefault();
    const t = $(e.target);
    const url = t.data('url');
    const zoneDescription = t.data('zone-description');

    $('.modal-header').html(`<h3>${zoneDescription}</h3>`);
    $('.modal-zone-photo-body').html(`<img src="${url}" />`);
    $('#original').attr({ href: url, target: 'blank' });
    return $('.zone-photo-modal').modal('show');
  },
});
export default ControllerEditingView;
