import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';
import siteDetailsTemplate from '@assets/javascripts/backbone/templates/sites/site_details.ejs';
import siteInfoWindowTemplate from '@assets/javascripts/backbone/templates/sites/site_info_window.ejs';
import siteInfoWindowTitleTemplate from '@assets/javascripts/backbone/templates/sites/site_info_window_title.ejs';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const DTSitesView = Backbone.View.extend({
  // template: siteDetailsTemplate,
  el: 'body',
  events: {
    'keydown .dataTables_filter input': Shared.delaySearch,
    'click #dataTable tbody tr': 'selectRow',
    'click #show_map': 'showMap',
    'click #hide_map': 'showMap',
    'click #export': Shared.exportFile,
  },
  buildSiteColumns() {
    const columns = [
      { data: 'more', name: 'more', visible: true },
      { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
      { data: 'link', name: 'sites.name', visible: true },
      { data: 'full_address', name: 'full_address', visible: window.COLUMNS.full_address.visible },
      { data: 'address1', name: 'address1', visible: window.COLUMNS.address1.visible },
      { data: 'address2', name: 'address2', visible: window.COLUMNS.address2.visible },
      { data: 'city', name: 'city', visible: window.COLUMNS.city.visible },
      { data: 'state', name: 'state', visible: window.COLUMNS.state.visible },
      { data: 'country', name: 'country', visible: window.COLUMNS.country.visible },
      { data: 'postal_code', name: 'postal_code', visible: window.COLUMNS.postal_code.visible },
      { data: 'longitude', name: 'longitude', visible: window.COLUMNS.longitude.visible },
      { data: 'latitude', name: 'latitude', visible: window.COLUMNS.latitude.visible },
      {
        data: 'controllers_count', name: 'controllers_count', visible: window.COLUMNS.controllers_count.visible, orderable: false,
      },
      {
        data: 'smartlink_type', name: 'smartlink_type', visible: window.COLUMNS.smartlink_type.visible, orderable: false,
      },
      {
        data: 'faults', name: 'faults', visible: window.COLUMNS.faults.visible, orderable: false,
      },
      {
        data: 'winterized', name: 'winterized', visible: window.COLUMNS.winterized.visible, orderable: false,
      },
      { data: 'created_at', name: 'created_at', visible: window.COLUMNS.created_at.visible },
      {
        data: 'sales_territory', name: 'sales_territory', visible: window.COLUMNS.sales_territory.visible, orderable: false,
      },
      {
        data: 'sales_reps', name: 'sales_reps', visible: window.COLUMNS.sales_reps.visible, orderable: false,
      },
      {
        data: 'has_ses_monitoring', name: 'has_ses_monitoring', visible: window.COLUMNS.has_ses_monitoring.visible, orderable: false,
      },
      {
        data: 'has_ses_reporting', name: 'has_ses_reporting', visible: window.COLUMNS.has_ses_monitoring.visible, orderable: false,
      },
      {
        data: 'uuid', name: 'uuid', visible: window.COLUMNS.uuid.visible, orderable: false,
      },
      {
        data: 'num_inspections_contracted', name: 'num_inspections_contracted', visible: window.COLUMNS.num_inspections_contracted.visible, orderable: false,
      },
      {
        data: 'gate_code', name: 'gate_code', visible: window.COLUMNS.gate_code.visible, orderable: false,
      },
      {
        data: 'property_manager', name: 'property_manager', visible: window.COLUMNS.property_manager.visible, orderable: false,
      },
      {
        data: 'property_manager_email', name: 'property_manager_email', visible: window.COLUMNS.property_manager_email.visible, orderable: false,
      },
      {
        data: 'property_manager_phone', name: 'property_manager_phone', visible: window.COLUMNS.property_manager_phone.visible, orderable: false,
      },
      {
        data: 'facility_partner', name: 'facility_partner', visible: window.COLUMNS.facility_partner.visible, orderable: false,
      },
      {
        data: 'facility_partner_email', name: 'facility_partner_email', visible: window.COLUMNS.facility_partner_email.visible, orderable: false,
      },
      {
        data: 'facility_partner_phone', name: 'facility_partner_phone', visible: window.COLUMNS.facility_partner_phone.visible, orderable: false,
      },
      {
        data: 'landscape_management_company', name: 'landscape_management_company', visible: window.COLUMNS.landscape_management_company.visible, orderable: false,
      },
      {
        data: 'landscape_management_email', name: 'landscape_management_email', visible: window.COLUMNS.landscape_management_email.visible, orderable: false,
      },
      {
        data: 'landscape_management_phone', name: 'landscape_management_phone', visible: window.COLUMNS.landscape_management_phone.visible, orderable: false,
      },
      {
        data: 'customer_name', name: 'customer_name', visible: window.COLUMNS.customer_name.visible, orderable: false,
      },
      {
        data: 'customer_email', name: 'customer_email', visible: window.COLUMNS.customer_email.visible, orderable: false,
      },
      {
        data: 'customer_phone', name: 'customer_phone', visible: window.COLUMNS.customer_phone.visible, orderable: false,
      },
      {
        data: 'site_supervisor', name: 'site_supervisor', visible: window.COLUMNS.site_supervisor.visible, orderable: false,
      },
      {
        data: 'contract_number', name: 'contract_number', visible: window.COLUMNS.contract_number.visible, orderable: false,
      },
      {
        data: 'meter_number', name: 'meter_number', visible: window.COLUMNS.meter_number.visible, orderable: false,
      },
      {
        data: 'meter_type', name: 'meter_type', visible: window.COLUMNS.meter_type.visible, orderable: false,
      },
      {
        data: 'water_source', name: 'water_source', visible: window.COLUMNS.water_source.visible, orderable: false,
      },
      {
        data: 'site_type', name: 'site_type', visible: window.COLUMNS.site_type.visible, orderable: false,
      },
      {
        data: 'preapproved_repair_limit', name: 'preapproved_repair_limit', visible: window.COLUMNS.preapproved_repair_limit.visible, orderable: false,
      },
      {
        data: 'maintenance_days', name: 'maintenance_days', visible: window.COLUMNS.maintenance_days.visible, orderable: false,
      },
      {
        data: 'maintenance_hours', name: 'maintenance_hours', visible: window.COLUMNS.maintenance_hours.visible, orderable: false,
      },
      {
        data: 'shortcuts', name: 'shortcuts', visible: window.COLUMNS.shortcuts.visible, orderable: false,
      },
    ];
    if (window.COLUMNS.branch) {
      columns.splice(-1, 0, {
        data: 'branch', name: 'branch', visible: window.COLUMNS.branch.visible, orderable: true,
      });
    }
    return columns;
  },
  async initialize() {
    const { Map } = await window.google.maps.importLibrary('maps');

    const map = new Map(document.getElementById('map'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      mapId: 'SITES_ID',
    });
    const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary(
      'marker',
    );
    window.PinElement = PinElement;
    window.AdvancedMarkerElement = AdvancedMarkerElement;
    window.map = map;
    window.timer = setTimeout(null, 0);
    window.markers = [];
    window.infos = [];
    this.siteDetails = siteDetailsTemplate;
    this.siteInfoWindow = siteInfoWindowTemplate;
    this.siteInfoWindowTitle = siteInfoWindowTitleTemplate;

    const onDatatableDraw = () => {
      this.drawMap();
      window.renderFilterBoxes();
      return Shared.fillBlankCells();
    };
    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      responsive: false,
      order: [[2, 'asc']],
      bAutoWidth: false,
      language: {
        emptyTable: '<div style="margin: 15px;">You don\'t have any sites yet.  <a href="/sites/new">Create one to get started</a>.<br/><br/><a href="/sites/new" class="btn btn-default"><span class="glyphicon glyphicon-plus scale-icon"></span>New Site</span>',
        lengthMenu: 'Show _MENU_',
        search: '',
        paginate: {
          previous: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.1663L1.33333 6.49967L6 1.83301" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          next: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.83366L5.66667 6.50033L1 11.167" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        info: `${window.I18n.showing} <strong>_START_</strong> ${window.I18n.data_tables.to} <strong>_END_</strong> ${window.I18n.of} <strong>_TOTAL_</strong> ${window.I18n.results}`,
      },
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, window.I18n.all]],

      // Place the site id inside each <tr>
      fnRowCallback(nRow, aData) {
        $(nRow).attr('id', aData.id).attr('n', aData.id);
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/sites.json',
        type: 'POST',
        data(params) {
          params.ransack = {};

          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => window.setRansackValue(key, value));

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            window.setRansackValue(key, val);
          });

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });
        },
        error: Shared.errorPopup,
      },
      stateLoadCallback() {
        const state = JSON.parse(localStorage.getItem(`DataTables_dataTable_${window.location.pathname}`));
        if (state) {
          state.order = [[2, 'asc']]; // Modify ordering on local storage
        }
        return state;
      },
      columns: this.buildSiteColumns(),
    });
    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').off();

    // $('table#dataTable thead th:first').html('<input id="select-all-sites" type="checkbox">')

    const regex = /[?&]q=([^&#]*)/g;
    const match = regex.exec(window.location.href);
    if (match?.[1]) {
      $('.dataTables_filter input').val(match[1]);
      Shared.search();
    }
  },
  showMap(event) {
    const show = event.currentTarget.id === 'show_map';
    if (show !== window.SHOWMAP) {
      window.SHOWMAP = show;
      if (window.SHOWMAP) {
        document.getElementById('show_map').classList.add('tw-bg-blue-100');
        document.getElementById('hide_map').classList.remove('tw-bg-blue-100');
        document.getElementById('map-row').closest('.row').classList.remove('hidden');
        this.drawMap();
      } else {
        document.getElementById('show_map').classList.remove('tw-bg-blue-100');
        document.getElementById('hide_map').classList.add('tw-bg-blue-100');
        document.getElementById('map-row').closest('.row').classList.add('hidden');
      }

      $.ajax({
        type: 'PUT',
        url: '/api/v2/users/0',
        data: {
          user: {
            shows_map: window.SHOWMAP,
          },
        },
      });
    }
  },
  formatSiteDetails(s) {
    return this.siteDetails({ site: s });
  },
  expandSiteDetails(e, tr = null) {
    tr = tr?.role === 'row' ? tr : e.target.closest('table').closest('tr').previousElementSibling;
    const row = window.table.row(tr);

    if (row.selector.rows) {
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.classList.remove('shown');
      } else {
        // Close all rows
        $('#dataTable tr').each((i, el) => {
          $(el).removeClass('shown');
          window.table.row(el).child.hide();
        });

        // Toggle pending chevron icon when expanding another row
        const element = $('.heroicon-chevron-down.tw-hidden').closest('td');
        element.find('.heroicon-chevron-down').removeClass('tw-hidden');
        element.find('.heroicon-chevron-up').addClass('tw-hidden');

        // Open this row
        row.child(this.formatSiteDetails(row.data())).show();
        tr.classList.add('shown');
      }

      Shared.toggleChevronIcon(tr);
    }
  },
  selectRow(event) {
    const target = event.target.role === 'row' ? event.target.children[0] : event.target.closest('td');

    if (target?.classList?.contains('dataTables_empty')) {
      return; // don't do anything if this is the 'empty table' message
    }

    if (target?.classList?.contains('site-checkbox')) {
      return; // don't do anything if this is the select site checkbox
    }

    if (!target?.classList?.contains('no_click')) {
      const tr = target.closest('tr').role === 'row' ? target.closest('tr') : event.target.closest('table').closest('tr')?.previousElementSibling;
      if (tr) {
        this.expandSiteDetails(event, tr);
        if (tr.classList.contains('selected')) {
          const tableRows = document.querySelectorAll('#dataTable tr');
          tableRows.forEach((row) => row.classList.remove('selected'));
          this.drawMap();
        } else {
          const tableRows = document.querySelectorAll('#dataTable tr');
          tableRows.forEach((row) => row.classList.remove('selected'));
          tr.classList.add('selected');

          if (window.SHOWMAP) {
            if (window.cluster) { window.cluster.clearMarkers(); }
            window.markers.forEach((mark) => mark.setMap(null));

            const marker = window.markers.find((w) => parseInt(w.title, 10) === parseInt(tr.getAttribute('id'), 10));
            const info = window.infos.find((i) => i.id === parseInt(tr.getAttribute('id'), 10));

            marker.setMap(window.map);
            window.map.setCenter(marker.position);
            window.map.setZoom(10);

            info.open(window.map, marker);
          }
        }
      }
    }
  },
  drawMap() {
    if (window.SHOWMAP) {
      if (window.cluster) {
        window.cluster.clearMarkers();
      }

      window.markers.forEach((marker) => marker.setMap(null));
      window.markers.length = 0;
      window.infos.length = 0;
      const bounds = new window.google.maps.LatLngBounds();

      let n = 0;

      window.table.data().each((r) => {
        if (r.latitude && r.longitude) {
          const rainCtrls = r.controllers.filter((controller) => controller.swStatus === 2);
          const delayCtrls = r.controllers.filter((controller) => controller.swStatus === 4);
          const wxEnabledCtrls = r.controllers.filter((controller) => controller.swStatus > 0);

          const headerContent = document.createElement('div');
          headerContent.innerHTML = this.siteInfoWindowTitle({ site: r });

          const info = new window.google.maps.InfoWindow({
            id: r.id,
            n,
            content: this.siteInfoWindow({ site: r }),
            headerContent,
            maxWidth: 332,
          });

          const gmapsIconRoot = '//maps.google.com/mapfiles/ms/icons/';
          let iconPath = `${gmapsIconRoot}green`;

          if (r.faultCount > 0) {
            iconPath = `${gmapsIconRoot}red-dot.png`;
          } else if (rainCtrls.length === wxEnabledCtrls.length) {
            iconPath = `${gmapsIconRoot}blue-dot.png`;
          } else if (delayCtrls.length === wxEnabledCtrls.length) {
            iconPath = `${gmapsIconRoot}orange-dot.png`;
          } else if (rainCtrls.length + delayCtrls.length === wxEnabledCtrls.length) {
            iconPath = '/images/blue-orange-dot.png';
          }

          const glyphImg = document.createElement('img');
          glyphImg.src = iconPath;
          const glyphPinElement = new window.PinElement({
            glyphColor: 'transparent',
            background: 'transparent',
            borderColor: 'transparent',
            glyph: glyphImg,
          });

          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            title: `${r.id}`,
            content: glyphPinElement.element,
            position: new window.google.maps.LatLng(parseFloat(r.latitude), parseFloat(r.longitude)),
          });

          window.markers.push(marker);
          window.infos.push(info);
          bounds.extend(marker.position);

          window.google.maps.event.addListener(marker, 'click', () => {
            window.markers.forEach(() => window.infos.forEach((i) => i.close()));
            info.open(window.map, marker);
            $('#sites tr').removeClass('selected');

            window.map.setCenter({ lat: marker.position.lat, lng: marker.position.lng });
            const tr = document.querySelector(`#sites-index tr[id='${info.id}']`);
            tr.classList.add('selected');
            this.expandSiteDetails(null, tr);
          });

          n += 1;
        }
      });
      window.cluster = new MarkerClusterer({ markers: window.markers, map: window.map });
      window.map.fitBounds(bounds);
    }
  },
});
export default DTSitesView;
