function updateSkeleton() {
  window.getFullAlertAgeReport();
  window.getUrgentAlertsInsights();
  window.getConnectivityHealthChart();
  if (document.getElementById('portfolio_signal_health')) {
    window.getInsightsPortfolioSignal(true);
    window.getConnectivityHealthTrend();
  }
  ['active_controllers', 'aircard_error', 'slw_error'].forEach((tab) => {
    document.querySelector(`#connectivity_health_detail_modal #${tab} #content`).innerHTML = '';
    document.querySelector(`#connectivity_health_detail_modal #${tab}`).classList.remove('no_data');
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const healthBody = document.getElementById('insights-connectivity_health_metrics-index');
  if (healthBody) {
    window.displayLastUpdatedDate('healths-index');
    // Skeleton loading state w/ spinner
    window.loadAnimationPending('#detail_alert_age #loading  #loading-state');
    window.loadAnimationPending('#overview_alert_age #loading  #loading-state');
    window.loadAnimationPending('#urgent-alerts #loading-state');
    window.loadAnimationPending('#connectivity-health-body #loading  #loading-state');
    if (document.getElementById('portfolio_signal_health')) {
      window.loadAnimationPending('#portfolio_signal_health #loading  #loading-state');
      window.loadAnimationPending('#portfolio_health_trend #loading  #loading-state');
    }
    window.loadAnimationPending('#connectivity_health_detail_modal #loading #loading-state');

    // Ajax requests
    window.getConnectivityHealthChart();
    window.getUrgentAlertsInsights();
    window.loadAlertReport('detail', true);
    if (document.getElementById('portfolio_signal_health')) {
      window.getInsightsPortfolioSignal(true);
      window.getConnectivityHealthTrend();
    }
    document.querySelectorAll('#detail_alert_age #content .sorting').forEach((sorteableHeader) => {
      sorteableHeader.addEventListener('click', window.changeSort);
      sorteableHeader.addEventListener('click', () => {
        window.loadAlertReport('detail', true);
      });
    });

    document.getElementById('search-alert-events').addEventListener('keyup', window.debounce(() => {
      window.loadAlertReport('detail', true);
    }, 500));

    document.getElementById('select_branch')?.addEventListener('change', () => {
      const branchSelector = document.querySelector('#select_branch #branch_id');
      if (branchSelector.value !== branchSelector.dataset.previousValue) {
        branchSelector.dataset.previousValue = branchSelector.value;
        document.getElementById('search-alert-events').value = '';
        window.selectChosenOption(document.getElementById('urgency_types-'));
        updateSkeleton();
      }
    });

    document.querySelectorAll('#portfolio_signal_health #controllers_content .sorting').forEach((sorteableHeader) => {
      sorteableHeader.addEventListener('click', window.changeSort);
      sorteableHeader.addEventListener('click', () => {
        window.getInsightsPortfolioSignal(false);
      });
    });
  }
});
