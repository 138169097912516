function getCompletedInspections() {
  window.$.ajax({
    url: '/insights/inspections_proposals/completed_inspections',
    type: 'GET',
    dataType: 'script',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value },
    success: () => {
      ['monthly', 'quarterly'].forEach((period) => {
        document.querySelector(`.${period} p.inspections-total`)?.addEventListener('click', () => window.changeInspectionsCompletedTab(period, 'scheduled_inspections'));
        document.querySelector(`.${period} p.inspections`)?.addEventListener('click', () => window.changeInspectionsCompletedTab(period, 'inspections_performed'));
        document.querySelector(`#${period}_inspections_detail_modal .inspections_performed-btn`)?.addEventListener('click', () => window.changeInspectionsCompletedTab(period, 'inspections_performed'));
        document.querySelector(`#${period}_inspections_detail_modal .scheduled_inspections-btn`)?.addEventListener('click', () => window.changeInspectionsCompletedTab(period, 'scheduled_inspections'));
        const goToInspections = document.querySelector(`#${period}_inspections_detail_modal #inspections_performed .go_to_inspections a`);
        const newUrl = window.changeDataFromUrl(goToInspections.href, { 'ransack[branch_id_in]': [document.querySelector('#select_branch #branch_id')?.value] });
        goToInspections.href = newUrl;
      });
    },
  });
}

function getInspectionCompletedByInspector() {
  window.$.ajax({
    url: '/insights/inspections_proposals/inspection_completed_by_inspector',
    type: 'GET',
    dataType: 'script',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value },
  });
}

function getProposalsStatus() {
  window.$.ajax({
    url: '/insights/inspections_proposals/proposals_status',
    type: 'GET',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value },
    dataType: 'script',
    success: () => {
      document.querySelector('#proposals-status .generated p')?.addEventListener('click', () => window.changeProposalsDetailTab('proposals_generated'));
      document.querySelector('#proposals-status .not-generated p')?.addEventListener('click', () => window.changeProposalsDetailTab('proposals_not_generated'));
      document.querySelector('#proposals-status .not-needed p')?.addEventListener('click', () => window.changeProposalsDetailTab('proposals_not_needed'));

      document.querySelector('#proposals_detail_modal .proposals_generated-btn')?.addEventListener('click', () => window.changeProposalsDetailTab('proposals_generated'));
      document.querySelector('#proposals_detail_modal .proposals_not_generated-btn')?.addEventListener('click', () => window.changeProposalsDetailTab('proposals_not_generated'));
      document.querySelector('#proposals_detail_modal .proposals_not_needed-btn')?.addEventListener('click', () => window.changeProposalsDetailTab('proposals_not_needed'));
    },
  });
}

function displayLastUpdatedDate(elementId) {
  const date = document.getElementById(elementId).dataset.date;
  if (date) {
    const newDate = new Date(date);
    const options = {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true, timeZoneName: 'short',
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(newDate).replace(',', '').replace(' AM', 'am')
      .replace(' PM', 'pm');
    document.getElementById('last_updated_on').innerHTML = window.I18n.insights.last_updated_on.replace('%{updated_at}', formattedDate);
    document.getElementById('last_updated_on').classList.remove('tw-hidden');
  }
}
window.displayLastUpdatedDate = displayLastUpdatedDate;

function getSalesFunnel() {
  window.$.ajax({
    url: '/insights/inspections_proposals/sales_funnel',
    type: 'GET',
    dataType: 'script',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value },
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const inspectionsProposalsIndex = document.getElementById('insights-inspections_proposals-index');

  if (inspectionsProposalsIndex) {
    displayLastUpdatedDate('inspections_proposals');
    getCompletedInspections();
    getInspectionCompletedByInspector();
    getProposalsStatus();
    getSalesFunnel();
    document.getElementById('select_branch')?.addEventListener('change', () => {
      const branchSelector = document.querySelector('#select_branch #branch_id');
      if (branchSelector.value !== branchSelector.dataset.previousValue) {
        branchSelector.dataset.previousValue = branchSelector.value;
        getCompletedInspections();
        getInspectionCompletedByInspector();
        getProposalsStatus();
        getSalesFunnel();
      }
    });
  }
});
