import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTEventsView = Backbone.View.extend({

  el: 'body',
  events: {

    'keydown .dataTables_filter input': Shared.delaySearch,
    'click #export': Shared.exportFile,

  },
  buildEventsColumns() {
    const columns = [{ data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
      {
        data: 'controller_name', name: 'controller_name', orderable: true, visible: window.COLUMNS.controller_name.visible,
      },
      {
        data: 'controller_id', name: 'controller_id', orderable: true, visible: window.COLUMNS.controller_id.visible,
      },
      {
        data: 'site_name', name: 'site_name', orderable: true, visible: window.COLUMNS.site_name.visible,
      },
      {
        data: 'site_id', name: 'site_id', orderable: true, visible: window.COLUMNS.site_id.visible,
      },
      { data: 'date', name: 'date', visible: window.COLUMNS.date.visible },
      { data: 'date_with_timezone', name: 'date_with_timezone', visible: window.COLUMNS.date_with_timezone.visible },
      {
        data: 'urgency', name: 'urgency', visible: window.COLUMNS.urgency.visible, orderable: false,
      },
      {
        data: 'status', name: 'status', orderable: true, visible: window.COLUMNS.status.visible,
      },
      { data: 'is_fault', name: 'is_fault', visible: window.COLUMNS.is_fault.visible },
      { data: 'event_type_as_string', name: 'event_type_as_string', visible: window.COLUMNS.event_type_as_string.visible },
      {
        data: 'as_text', name: 'as_text', orderable: false, visible: window.COLUMNS.as_text.visible,
      },
      { data: 'site_address', name: 'site_address', visible: window.COLUMNS.site_address.visible },
      { data: 'site_city', name: 'site_city', visible: window.COLUMNS.site_city.visible },
      { data: 'site_state', name: 'site_state', visible: window.COLUMNS.site_state.visible },
      { data: 'site_billing_email', name: 'site_billing_email', visible: window.COLUMNS.site_billing_email.visible },
      {
        data: 'user_email', name: 'user_email', orderable: false, visible: window.COLUMNS.user_email.visible,
      },
      {
        data: 'user_email', name: 'user_email', orderable: false, visible: window.COLUMNS.user_email.visible,
      }];
    if (window.COLUMNS.branch) {
      columns.splice(-1, 0, {
        data: 'branch', name: 'branch', visible: window.COLUMNS.branch.visible, orderable: true,
      });
    }
    return columns;
  },
  initialize() {
    window.view = this;

    const onDatatableDraw = () => {
      Shared.fillBlankCells();
      window.renderFilterBoxes();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[5, 'desc']],
      bAutoWidth: false,
      responsive: false,
      language: {
        emptyTable: '<div style="margin: 15px;">You don\'t have any events yet.  </div>',
        lengthMenu: 'Show _MENU_',
        infoFiltered: '',
        search: '',
        paginate: {
          previous: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.1663L1.33333 6.49967L6 1.83301" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          next: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.83366L5.66667 6.50033L1 11.167" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        info: `${window.I18n.showing} <strong>_START_</strong> ${window.I18n.data_tables.to} <strong>_END_</strong> ${window.I18n.of} <strong>_TOTAL_</strong> ${window.I18n.results}`,
      },
      searching: false,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // Color the row if is a is_fault
      // Add hover to color and a click event to expand description
      fnRowCallback(nRow, aData) {
        if (aData.is_fault_bool) { $(nRow).addClass('alert-danger'); }
        const descriptionCell = nRow.querySelector('.description_as_text').parentElement;
        descriptionCell.classList.add('tw-cursor-pointer', 'hover:tw-bg-blue-100');
        descriptionCell.setAttribute('onclick', "this.children[0].classList.remove('tw-rows-ellipsis')");
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/events.json',
        type: 'POST',
        data(params) {
          params.ransack = {};

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            if (element.value) {
              const value = window.getRansackInputValue(element);
              if (value) {
                params.ransack[element.id] = value;
              }
            }
          });

          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => window.setRansackValue(key, value));

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            window.setRansackValue(key, val);
          });
        },
        error: Shared.errorPopup,
      }, // This return is very important for some reason

      columns: this.buildEventsColumns(),
    });
    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').unbind();
  },
});
export default DTEventsView;
