function proposalsFilters(type) {
  const filter = { type, per_page: 5, branch_id: document.querySelector('#select_branch #branch_id')?.value };
  let addedFilter = {};
  switch (type) {
    case 'proposals_generated':
      addedFilter = { proposal_id_not_null: 1 };
      break;
    case 'proposals_not_generated':
      addedFilter = { proposal_id_not_null: 0, inspection_status_eq: 2 };
      break;
    case 'proposals_not_needed':
      addedFilter = { proposal_id_not_null: 0, inspection_status_eq: 0 };
      break;
    default:
      addedFilter = { proposal_id_not_null: 1 };
      break;
  }
  filter.filter = addedFilter;
  return filter;
}

function getModalMenuButtonPosition(page) {
  const buttons = {
    proposals_generated: 1,
    proposals_not_generated: 2,
    proposals_not_needed: 3,
  };
  return page === 'proposals_generated' ? 0 : document.getElementById('scrollableMenu').getElementsByTagName('li')[buttons[page]].offsetLeft;
}

function updateProposalsDetailModalButtons(type) {
  const modal = document.getElementById('proposals_detail_modal');
  modal.classList.remove('tw-hidden');
  const proposalsTypes = ['proposals_not_needed', 'proposals_not_generated', 'proposals_generated'];
  proposalsTypes.splice(proposalsTypes.indexOf(type), 1);

  proposalsTypes.forEach((tabName) => {
    modal.querySelector(`#${tabName}`).classList.add('tw-hidden');
    modal.querySelector(`.${tabName}-btn`).classList.remove('tw-btn-action-blue');
    modal.querySelector(`.${tabName}-btn`).classList.add('tw-text-blue-500');
  });

  modal.querySelector(`#${type}`).classList.remove('tw-hidden');
  modal.querySelector(`.${type}-btn`).classList.add('tw-btn-action-blue');
  modal.querySelector(`.${type}-btn`).classList.remove('tw-text-blue-500');
  document.getElementById('scrollableMenu').scrollLeft = getModalMenuButtonPosition(type);
  window.scrollMenuVerification('scrollableMenu', 'menuGoForward', 'menuGoBack');
}

function updateProposalsDetail(type) {
  updateProposalsDetailModalButtons(type);
  window.loadAnimationPending(`#proposals_detail_modal #${type} #loading-state`);
  window.$.ajax({
    url: '/insights/proposals',
    data: proposalsFilters(type),
    dataType: 'script',
    type: 'GET',
  });
}

function changeProposalsDetailTab(type) {
  const makeCall = (document.querySelector(`#proposals_detail_modal #${type} #proposals_detail > div`).id) === 'loading-data';
  if (makeCall) {
    updateProposalsDetail(type);
  } else {
    updateProposalsDetailModalButtons(type);
  }
}
window.changeProposalsDetailTab = changeProposalsDetailTab;
