function getConnectivityHealthChart() {
  const loader = document.querySelector('#connectivity-health-body #loading');
  document.getElementById('connectivity-health-content').classList.add('tw-hidden');
  document.querySelector('#connectivity-health-body #empty').classList.add('tw-hidden');
  loader.classList.remove('tw-hidden');
  window.$.ajax({
    url: '/insights/connectivity_health_metrics/controllers_health',
    method: 'GET',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value },
    success: () => {
      loader.classList.add('tw-hidden');
    },
  });
}
window.getConnectivityHealthChart = getConnectivityHealthChart;

function getConnectivityHealthDetail(tabName) {
  const loader = document.querySelector('#connectivity_health_detail_modal #loading');
  ['active_controllers', 'aircard_error', 'slw_error'].forEach((tab) => {
    document.querySelector(`#connectivity_health_detail_modal #${tab}`).classList.add('tw-hidden');
  });
  document.querySelector('#connectivity_health_detail_modal #empty').classList.add('tw-hidden');
  loader.classList.remove('tw-hidden');
  window.$.ajax({
    url: '/insights/connectivity_health_metrics/connectivity_health_detail',
    method: 'GET',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value, tab_name: tabName },
    success: () => {
      loader.classList.add('tw-hidden');
    },
  });
}

function changeHealthDetailTabs(tabName) {
  const tabs = ['active_controllers', 'aircard_error', 'slw_error'].filter((tab) => tab !== tabName);
  let tabElement = document.querySelector(`#connectivity_health_detail_modal #${tabName}-btn`);
  tabElement.classList.add('tw-btn-action-blue');
  tabElement.classList.remove('tw-text-blue-500');
  tabElement.querySelector('p').classList.remove('tw-hidden');

  tabs.forEach((noSelectedTab) => {
    tabElement = document.querySelector(`#connectivity_health_detail_modal #${noSelectedTab}-btn`);
    tabElement.classList.remove('tw-btn-action-blue');
    tabElement.classList.add('tw-text-blue-500');
    tabElement.querySelector('p').classList.add('tw-hidden');
  });
}

function showEmptyHealthDetailTab(tabName) {
  document.querySelector('#connectivity_health_detail_modal #empty').classList.remove('tw-hidden');
  document.querySelector('#connectivity_health_detail_modal #empty .title').innerText = window.I18n.insights.healths.connectivity_health.detail_modal.empty_state_titles[tabName];
  if (tabName === 'active_controllers') {
    document.querySelector('#connectivity_health_detail_modal #empty .icon-circle-check path').setAttribute('d', 'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z');
  } else {
    document.querySelector('#connectivity_health_detail_modal #empty .icon-circle-check path').setAttribute('d', 'M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z');
  }
}
window.showEmptyHealthDetailTab = showEmptyHealthDetailTab;

function switchConnectivityHealthDetailTab(tabName, force = false) {
  if (document.querySelector(`#connectivity_health_detail_modal #${tabName}-btn`).classList.contains('tw-text-blue-500') || force) {
    changeHealthDetailTabs(tabName);
    document.querySelector('#connectivity_health_detail_modal #loading').classList.add('tw-hidden');
    ['active_controllers', 'aircard_error', 'slw_error'].forEach((tab) => {
      document.querySelector(`#connectivity_health_detail_modal #${tab}`).classList.add('tw-hidden');
    });
    if (document.querySelector(`#connectivity_health_detail_modal #${tabName}`).classList.contains('no_data')) {
      showEmptyHealthDetailTab(tabName);
    } else if (document.querySelector(`#connectivity_health_detail_modal #${tabName} #content`).children.length === 0) {
      getConnectivityHealthDetail(tabName);
    } else {
      document.querySelector('#connectivity_health_detail_modal #empty').classList.add('tw-hidden');
      document.querySelector(`#connectivity_health_detail_modal #${tabName}`).classList.remove('tw-hidden');
    }
  }
}

window.switchConnectivityHealthDetailTab = switchConnectivityHealthDetailTab;
