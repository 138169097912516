function getUrgentAlertsInsights(tab = null, orderBy = null) {
  const loader = document.querySelector('#urgent-alerts #loading');
  document.getElementById('urgent-alerts-content').classList.add('tw-hidden');
  document.querySelector('#urgent-alerts #empty').classList.add('tw-hidden');
  loader.classList.remove('tw-hidden');
  window.$.ajax({
    url: '/insights/connectivity_health_metrics/urgent_alerts',
    method: 'GET',
    data: { branch_id: document.querySelector('#select_branch #branch_id')?.value, tab, order_by_events: orderBy },
    success: () => {
      loader.classList.add('tw-hidden');
    },
  });
}
window.getUrgentAlertsInsights = getUrgentAlertsInsights;

function changeUrgentAlertTab(tabName) {
  const tabNumber = { high: 'N3', medium: 'N2', low: 'N1' };
  const tabs = ['high', 'medium', 'low'].filter((tab) => tab !== tabName);
  const emptyState = document.querySelector('#urgent-alerts #empty');
  if (document.querySelector(`#urgent_alerts-${tabName}`)) {
    document.querySelector(`#urgent_alerts-${tabName}`).classList.remove('tw-hidden');
    emptyState.classList.add('tw-hidden');
  } else {
    emptyState.querySelector('.title').innerText = window.I18n.insights.healths.urgent_alerts.empty_state.replace('%{urgency}', window.I18n.models.events.urgency[tabNumber[tabName]].toLowerCase());
    emptyState.classList.remove('tw-hidden');
  }
  document.querySelector(`#${tabName}_alerts-tab button`).classList.add('tw-text-white', 'tw-bg-blue-500');
  document.querySelector(`#${tabName}_alerts-tab button`).classList.remove('tw-bg-white', 'tw-text-blue-500');
  document.querySelector(`#${tabName}_alerts-tab button span`).classList.remove('tw-hidden');

  tabs.forEach((otherTab) => {
    document.querySelector(`#urgent_alerts-${otherTab}`)?.classList.add('tw-hidden');
    document.querySelector(`#${otherTab}_alerts-tab button`).classList.remove('tw-text-white', 'tw-bg-blue-500');
    document.querySelector(`#${otherTab}_alerts-tab button`).classList.add('tw-bg-white', 'tw-text-blue-500');
    document.querySelector(`#${otherTab}_alerts-tab button span`).classList.add('tw-hidden');
  });
}

window.changeUrgentAlertTab = changeUrgentAlertTab;

function urgentAlertsListener() {
  ['high', 'medium', 'low'].forEach((tabName) => {
    if (document.querySelector(`#urgent_alerts-${tabName}`)) {
      document.querySelector(`#urgent_alerts-${tabName} .headers .sorting`).addEventListener('click', window.changeSort);
      document.querySelector(`#urgent_alerts-${tabName} .headers .sorting`).addEventListener('click', (event) => { getUrgentAlertsInsights(tabName, event.target.classList.contains('sorting_desc') ? 'DESC' : 'ASC'); });
    }
  });
}
window.urgentAlertsListener = urgentAlertsListener;
