function exportEquipmentOrder() {
  const orderId = document.getElementById('equipment_order').dataset.equipmentOrder;
  const orderNumber = document.getElementById('equipment_order').dataset.equipmentOrderNumber;
  const accountId = document.getElementById('equipment_order').dataset.accountId;
  const headers = new Headers({ 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content });
  const url = `/accounts/${accountId}/equipment_orders/${orderId}.xlsx`;
  fetch(url, {
    method: 'GET',
    headers,
  })
    .then((res) => res.blob())
    .then((blob) => {
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = `Equipment Order ${orderNumber}.xlsx`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });
}
window.exportEquipmentOrder = exportEquipmentOrder;

function deleteOrderDetails() {
  const data = document.getElementById('equipment_order').dataset;
  window.$.ajax({
    url: `/accounts/${data.accountId}/equipment_orders/${data.equipmentOrder}`,
    dataType: 'script',
    type: 'DELETE',
    error: () => {
      document.getElementById('warning-modal').classList.add('tw-hidden');
      window.toggleSnackbar('warning-snackbar', window.I18n.generic_error);
    },
  });
}

function showDeleteOrderModal() {
  const modal = document.getElementById('warning-modal');
  modal.querySelector('.tw-btn-action-red').onclick = () => deleteOrderDetails();
  modal.classList.remove('tw-hidden');
}

window.showDeleteOrderModal = showDeleteOrderModal;

function updateEquipmentOrder() {
  const orderId = document.getElementById('equipment_order').dataset.equipmentOrder;
  const accountId = document.getElementById('equipment_order').dataset.accountId;
  const updateBody = { order_items: [] };
  document.querySelectorAll('textarea[data-changed]').forEach((note) => {
    updateBody.order_items.push({ id: note.dataset.id, notes: note.value });
  });
  updateBody.payment_start = document.querySelector('#payment_start').value;
  updateBody.payment_end = document.querySelector('#payment_end').value;
  window.$.ajax({
    url: `/accounts/${accountId}}/equipment_orders/${orderId}`,
    data: JSON.stringify(updateBody),
    contentType: 'application/json; charset=utf-8',
    dataType: 'script',
    type: 'PUT',
  });
}

function editEquipmentOrder() {
  document.querySelector('.edit_order').classList.add('tw-hidden');
  document.querySelector('.save_changes').classList.remove('tw-hidden');
  document.querySelector('.save_changes').onclick = () => updateEquipmentOrder();
  document.querySelector('.payment_detail-start').classList.add('tw-hidden');
  document.querySelector('.payment_detail-end').classList.add('tw-hidden');
  document.querySelectorAll('.calendar').forEach((item) => item.classList.remove('tw-hidden'));
  document.querySelectorAll('.note_input').forEach((noteInput) => {
    noteInput.classList.remove('tw-hidden');
    noteInput.previousElementSibling.classList.add('tw-hidden');
  });
}
window.editEquipmentOrder = editEquipmentOrder;

document.querySelector('#payment_start')?.addEventListener('change', (changeEvent) => {
  const pickerEnd = document.querySelector('#payment_end');
  if (changeEvent.target.value === '') {
    pickerEnd.datepicker.setOptions({ minDate: null });
  } else {
    pickerEnd.datepicker.setOptions({ minDate: new Date(`${changeEvent.target.value}:`) });
  }
});

document.querySelector('#payment_end')?.addEventListener('change', (changeEvent) => {
  const pickerStart = document.querySelector('#payment_start');
  if (changeEvent.target.value === '') {
    pickerStart.datepicker.setOptions({ maxDate: null });
  } else {
    pickerStart.datepicker.setOptions({ maxDate: new Date(`${changeEvent.target.value}:`) });
  }
});
